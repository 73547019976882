@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

:root {
  --primary: #ff6600;
  --secondary: #008080;
  --bg-color: #f8f9fd;
  --text-light: #b3b3b3;
  --text-dark: #616161;
  --text-black: #000;
  --red_color: #F56F70;
  --white: #fff;
  --transparent: rgba(0, 0, 0, 0);
}

.bg_color {
  --background: var(--bg-color);
}

.d-flex {
  display: flex !important;
  align-items: center;
  width: 100%;

  .end {
    margin-left: auto !important;
    margin-right: 0 !important;
    display: block;
  }
}

.center_img {
  position: relative;
  overflow: hidden;

  img {
    &.crop_img {
      position: absolute;
      min-width: 100%;
      min-height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

.color-text-primary {
  color: var(--primary) !important;
}

.color-text-seconday {
  color: var(--secondary) !important;
}

.background-text-primary {
  background: var(--primary) !important;
}
.background-text-seconday {
  background: var(--secondary) !important;
}

.button {
  &.btn {
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.6px !important;
    height: 55px;
    --background: var(--primary);
    color: var(--white);
    margin: 0;
    --box-shadow: none;
    text-transform: none;

    ion-icon {
      font-size: 1.4rem;
    }

    &:hover:not(.disable-hover) {
      --background: var(--primary);
    }

    &.button-outline {
      --background: var(--transparent);
      color: var(--white);
      --border-color: var(--primary);
      --border-width: 1px;

      &:hover:not(.disable-hover) {
        --background: var(--transparent);
      }
    }

    &.button-round {
      --border-radius: 50px !important;
    }

    &.button-block {
      --border-radius: 6px !important;
    }
  }
}

ion-header {
  z-index: 999;

  &::after {
    display: none !important;
  }

  ion-toolbar {
    --background: var(--white);
    display: flex;
    align-items: center;
    min-height: 70px !important;
    height: auto;
    overflow: visible !important;
    --border-width: 0 !important;

    ion-buttons {
      position: relative !important;
      margin: 0;
      --padding-end: 0 !important;
      --padding-start: 0 !important;

      ion-back-button {
        --color: var(--text-black) !important;
        --icon-font-size: 1.6rem !important;
      }

      ion-menu-button {
        --color: var(--text-black) !important;
        --padding-end: 0 !important;
        font-size: 1.8rem !important;
        margin-right: 12px;

        &.ios {
          font-size: 2rem !important;
        }
      }

      ion-back-button {
        .button-native {
          padding: 0 !important;
        }
      }

      &.ios {
        padding: 0 10px !important;
      }
    }

    ion-title {
      color: var(--text-black);
      padding: 0 15px !important;
      padding-left: 0 !important;
      text-align: justify !important;
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 0;
      text-transform: none;
      display: block;
      position: relative !important;
      left: 0;
      overflow: visible !important;
      line-height: 70px;
    }
  }

  &.bg_transparent {
    ion-toolbar {
      --background: transparent;
      --ion-color-base: transparent !important;
    }
  }
}

.form {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
  width: calc(100% - 0px);
  z-index: 99;
  padding: 0 20px;

  ion-list {
    background: var(--transparent) !important;
    margin: 0;
    padding: 0;
  }

  ion-item {
    padding: 0px 0px;
    width: calc(100% - 0px);
    min-height: unset !important;
    box-shadow: none !important;
    margin: 0 auto;
    --background: var(--transparent);
    align-items: flex-start;
    --inner-padding-end: 0px;
    --inner-min-height: unset !important;
    --min-height: unset !important;
    --padding-start: 0;
    box-shadow: none !important;
    margin-bottom: 30px;
    --ripple-color: var(--transparent) !important;
    --background-focused: var(--transparent) !important;

    ion-icon {
      color: var(--primary);
      margin: 0;
      font-size: 1rem;
      min-width: 30px;
      height: 37px;
      line-height: 55px;
    }

    .icon_box {
      min-width: 35px;

      img {
        width: 20px;
      }
    }

    ion-label {
      color: var(--text-dark) !important;
      font-size: 0.73rem !important;
      font-weight: 500;
      margin-bottom: 6px !important;
      transform: unset !important;
      text-transform: uppercase;
      letter-spacing: 0.5px !important;
    }

    .item_inner {
      &.d-flex {
        align-items: flex-start;
      }
    }

    .input_box {
      width: 100%;
      border-bottom: 1px solid rgba(204, 204, 204, 0.6) !important;
      padding-bottom: 5px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 0;
        height: 1px;
        background: var(--primary);
        transition: all .3s;
      }
    }

    ion-textarea,
    ion-input {
      --padding: 0 !important;
      font-size: .95rem !important;
      font-weight: 400 !important;
      letter-spacing: 0;
      --padding-start: 0 !important;
      --padding-end: 0 !important;
      --padding-top: 0 !important;
      --padding-bottom: 0px !important;
      color: var(--text-black);
      --placeholder-color: var(--text-light) !important;
      --placeholder-opacity: 1;
      --placeholder-font-weight: 400 !important;
      flex: unset !important;

      input {
        --padding: var(--no-padding);

        &.native-input {
          --padding: var(--no-padding);
        }
      }
    }

    &.item-select {
      ion-select {
        padding: 0;
        font-size: 1rem !important;
        font-weight: 400 !important;
        letter-spacing: 0;
        color: var(--text-black);
        --placeholder-color: var(--text-light);
        --placeholder-opacity: 1;
      }
    }

    h5 {
      margin: 0;
      color: var(--primary);
      font-size: .85rem;
      height: 45px;
      line-height: 55px;
      letter-spacing: .4px;
    }

    &.item-has-focus {
      .input_box {
        &::after {
          width: 100%;
        }
      }
    }
  }

  ion-row {
    margin: 0 -5px;
  }
}

ion-segment {
  ion-segment-button {
    --indicator-box-shadow: none !important;
    --indicator-color: var(--transparent) !important;
    --indicator-height: 0px !important;
    --ripple-color: var(--transparent) !important;
  }
}

ion-modal {
  --background: rgba(0, 0, 0, 0.42) !important;
  --backdrop-opacity: .1 !important;
}

.alert-wrapper {
  border-radius: 13px !important;
  min-width: calc(100% - 58px) !important;
  max-height: 484px !important;
  height: auto;

  .alert-head {
    background: var(--bg-color);
    padding: 20px 27px 16px 27px;

    h2 {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 1.2rem;
    }
  }

  .alert-radio-group {
    max-height: 291px !important;

    .alert-radio-button {
      .alert-button-inner {
        .alert-radio-label {
          font-size: 1.2rem;
          color: var(--text-black);
        }
      }
    }
  }

  .alert-button-group {
    padding: 0;
    display: flex;
    align-items: center;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 1.5px;
      height: 100%;
      background-color: var(--white);
      z-index: 9999;
      margin: 0 auto;
    }

    .alert-button {
      color: var(--white);
      background: var(--primary);
      height: 50px;
      text-align: center;
      margin: 0;
      width: 50%;

      .alert-button-inner {
        width: 100%;
        display: block;
        height: fit-content;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.7px;
      }
    }
  }
}

app-restaurant-items,
app-table-booking-stores,
app-items,
app-stores,
app-home,
app-set-location {
  ion-searchbar {
    .searchbar-input-container {
      input {
        box-shadow: none !important;
        padding: 0 !important;
        font-size: .97rem !important;
        font-weight: 500 !important;
      }
    }
  }
}

// app-settings {
//     ion-header ion-toolbar ion-title {
//         text-align: center !important;
//         width: calc(100% + 15px);
//     }
//     &.can-go-back {
//         ion-header ion-toolbar ion-title {
//             text-align: justify !important;
//             width: calc(100% + 0px);
//         }
//     }
// }
ion-app[dir="rtl"] {
  .d-flex .end {
    margin-left: 0 !important;
    margin-right: auto !important;
  }

  ion-header ion-toolbar {
    ion-buttons ion-back-button {
      transform: rotate(180deg) !important;
    }

    ion-title {
      padding-left: 15px !important;
      padding-right: 0 !important;
    }

    ion-buttons ion-menu-button {
      margin-right: 0;
      margin-left: 12px;
    }
  }

  app-saved-addresses {
    ion-list ion-item .item_inner {
      &::after {
        left: unset !important;
        right: 70px !important;
      }

      .img_box {
        margin-right: 0px !important;
        margin-left: 30px !important;
      }
    }
  }

  app-set-location {
    ion-footer {
      .current_location {
        margin-left: 20px !important;
        margin-right: auto !important;
      }

      .address h2 span.map_pin {
        background-position: right !important;
      }

      .form ion-list .address_title ion-row ion-col ion-item ion-label .img_container .img_box {
        margin-right: 0px !important;
        margin-left: 7px !important;
      }
    }
  }

  app-table-booking-stores,
  app-stores {
    ion-list ion-item .item_inner .img_box {
      margin-right: 0px !important;
      margin-left: 12px !important;
    }
  }

  app-restaurant-items,
  app-items {
    ion-list ion-item .item_inner .img_box {
      margin-right: 0px !important;
      margin-left: 12px !important;
    }
  }

  app-add-money-option,
  app-payment {
    ion-list ion-item .item_inner .payment_option .img_box {
      margin-right: 0px !important;
      margin-left: 17px !important;
    }
  }

  app-my-orders {
    ion-list ion-item .item_inner {
      .item_info .itme_img {
        margin-right: 0px !important;
        margin-left: 15px !important;
      }

      .address_info {
        .from_to .icon_box {
          margin-right: 0px !important;
          margin-left: 15px !important;
        }
      }
    }
  }

  app-order-detail {
    ion-header ion-item .item_inner {
      .item_info .itme_img {
        margin-right: 0px !important;
        margin-left: 15px !important;
      }

      .address_info {
        .from_to .icon_box {
          margin-right: 0px !important;
          margin-left: 15px !important;
        }
      }
    }

    ion-list ion-item {
      &.profile_info .item_inner .img_box {
        margin-right: 0px !important;
        margin-left: 15px !important;
      }
    }
  }

  app-chat {
    ion-header .profile_info .img_box {
      margin-right: 0px !important;
      margin-left: 15px !important;
    }

    .chat_container .chat_box {
      &.send {
        margin-left: 0 !important;
        margin-right: auto !important;

        .chat {
          margin-left: 0 !important;
          margin-right: auto !important;
          padding: 9px 40px 10px 14px !important;

          h2 {
            text-align: left !important;
          }

          p {
            text-align: left !important;
          }
        }
      }
    }
  }

  app-custom-delivery {
    ion-list ion-card.address_info .dotted_icon {
      left: unset !important;
      right: 8px !important;
    }
  }

  app-package-type {
    ion-list ion-item ion-checkbox {
      margin-right: 0 !important;
      margin-left: 15px !important;
    }
  }

  ion-tabs ion-tab-bar ion-tab-button .tab-button.selected .icon_box {
    margin-right: 0px !important;
    margin-left: 8px !important;
  }

  .buy_this_app {
    right: unset !important;
    left: 20px !important;
  }

  app-wallet {
    ion-content .button.btn {
      right: unset !important;
      left: 20px !important;
    }
  }

  app-offers {
    ion-list ion-item .item_inner::after {
      right: unset !important;
      left: 100px !important;
    }
  }

  app-home {
    .offers_for_you .scroll_container .offer,
    .what_do_you_lick .category_row .category {
      &:first-child {
        margin-left: 4px !important;
        margin-right: 20px !important;
      }

      &:lastt-child {
        margin-right: 4px !important;
        margin-left: 20px !important;
      }
    }
  }

  ion-list .scroll_container .list_inner {
    &:first-child {
      margin-left: 12px !important;
      margin-right: 20px !important;
    }

    &:lastt-child {
      margin-right: 12px !important;
      margin-left: 20px !important;
    }

    ion-item .item_inner .img_box {
      margin-right: 0 !important;
      margin-left: 12px !important;
    }
  }

  .book_table ion-row ion-col .option img {
    margin-right: 0 !important;
    margin-left: 9px !important;
  }

  app-variation-selection {
    ion-list ion-item ion-checkbox {
      margin-right: unset !important;
      margin-left: 15px !important;
    }
  }

  app-ordering-method,
  app-table-booking {
    ion-footer .form .radio_list ion-radio-group .scroll-x ion-item {
      &:first-child {
        margin-left: 5px !important;
        margin-right: 20px !important;
      }

      &:last-child {
        margin-right: 5px !important;
        margin-left: 20px !important;
      }
    }
  }

  .form ion-item .input_box::after {
    left: unset !important;
    right: 0 !important;
  }

  app-favorites {
    ion-list ion-item .item_inner .img_box {
      margin-right: 0 !important;
      margin-left: 12px !important;
    }
  }
}

.dark-theme {
  --bg-color: #000000 !important;
  --bg-secondary: #212321 !important;
  --text-light: #b3b3b3 !important;
  --text-dark: #888888 !important;
  --text-black: #fff !important;

  .form ion-item ion-textarea,
  .form ion-item ion-input {
    color: var(--white);
  }

  ion-header ion-toolbar {
    --background: var(--bg-color);

    ion-buttons ion-back-button {
      --color: var(--white) !important;
    }

    ion-title {
      color: var(--white);
      font-weight: 500;
    }
  }

  ion-content {
    --background: var(--bg-color);
  }

  ion-content {
    &.bg_color {
      --background: var(--bg-color);
    }
  }

  app-login {
    .banner .logo img {
      &.dark_theme_logo {
        display: block !important;
      }

      &.light_theme_logo {
        display: none !important;
      }
    }

    ion-footer {
      background: var(--bg-color) !important;

      .form {
        background: var(--bg-secondary) !important;

        ion-list ion-item h3 {
          color: var(--white);
        }
      }
    }
  }

  app-verification {
    h2 {
      color: var(--white);
    }

    ion-footer h3 {
      color: var(--white);
    }
  }

  app-set-location {
    ion-header {
      background: var(--bg-color);

      .search_box {
        background: var(--bg-secondary) !important;

        ion-icon {
          color: var(--text-light) !important;
        }

        ion-searchbar {
          color: var(--white) !important;
        }
      }
    }

    ion-footer {
      .current_location {
        background: var(--bg-secondary) !important;

        ion-icon {
          color: var(--white) !important;
        }
      }

      .address {
        background: var(--bg-secondary) !important;

        h2 {
          color: var(--white) !important;
          font-weight: 400;
        }
      }

      .form {
        background: var(--bg-color) !important;

        ion-list .address_title ion-row ion-col ion-item {
          background: var(--bg-secondary) !important;

          ion-label .img_container .img_box {
            img {
              &:nth-child(odd) {
                width: 0 !important;
              }

              &:nth-child(even) {
                width: 100% !important;
                height: auto !important;
              }
            }
          }
        }
      }
    }
  }

  app-home {
    ion-header {
      background: var(--bg-color);
    }

    .what_do_you_lick .category_row .category {
      background: var(--bg-secondary) !important;
    }

    ion-list ion-item {
      background: var(--bg-color) !important;
      --background: var(--bg-color) !important;
    }
  }

  app-table-booking-stores,
  app-favorites,
  app-stores {
    ion-header {
      background: var(--bg-color);

      .search_box {
        background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-item {
      background: var(--bg-color) !important;
    }
  }

  app-confirm-order,
  app-restaurant-items,
  app-cart,
  app-items {
    ion-header {
      background: var(--bg-color) !important;

      &::before {
        background: var(--bg-secondary) !important;
      }

      .search_box {
        background: var(--bg-secondary) !important;
      }
    }

    ion-list {
      h2 {
        background: var(--bg-secondary) !important;
      }

      ion-item {
        background: var(--bg-color) !important;

        .item_inner .text_box {
          h5 {
            background: var(--bg-secondary) !important;
            color: var(--text-black);
          }

          p {
            background: var(--primary);

            span {
              color: var(--text-black) !important;
            }

            strong {
              color: var(--text-black);
            }
          }

          h6 {
            background: var(--primary);
            color: var(--text-black) !important;
          }
        }
      }
    }

    ion-footer .cart_btn h2 span.end {
      background: var(--bg-color) !important;
    }

    ion-slides ion-slide {
      border-color: var(--bg-secondary) !important;
    }
  }

  app-confirm-order,
  app-cart {
    ion-header .shop_name {
      background: var(--bg-secondary) !important;
    }

    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list {
      .payment_info h1 {
        background: var(--bg-color) !important;
      }

      h2 {
        background: var(--bg-secondary) !important;
      }
    }

    ion-footer .delivery_address {
      background: var(--bg-color) !important;
    }
  }

  app-add-money-option,
  app-payment {
    ion-header {
      background: var(--bg-color) !important;
    }

    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list {
      h2 {
        background: var(--bg-secondary) !important;
      }

      ion-item {
        --background: var(--bg-color) !important;
        background: var(--bg-color) !important;

        .item_inner {
          background: var(--bg-color) !important;

          .payment_option h3 {
            color: var(--text-black) !important;
          }
        }
      }
    }
  }

  app-package-type,
  app-variation-selection {
    ion-list {
      background: var(--bg-color) !important;

      .list_header {
        background: var(--bg-secondary) !important;

        h6 {
          background: var(--primary) !important;
          color: var(--text-black) !important;
        }
      }

      ion-radio-group {
        background: var(--bg-color) !important;

        ion-item {
          background: var(--bg-color) !important;
          --background: var(--bg-color) !important;
        }
      }
    }
  }

  app-package-type {
    ion-list {
      background: var(--bg-color) !important;

      ion-item {
        background: var(--bg-color) !important;
        --background: var(--bg-color) !important;
      }
    }
  }

  app-my-orders {
    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-item {
      background: var(--bg-color) !important;
      --background: var(--bg-color) !important;
    }
  }

  app-order-detail {
    ion-header {
      background: var(--bg-color) !important;

      ion-item {
        background: var(--bg-color) !important;
        --background: var(--bg-color) !important;
      }
    }

    ion-list {
      &::before {
        background: var(--bg-secondary) !important;
      }

      ion-item {
        background: var(--bg-color) !important;
        --background: var(--bg-color) !important;

        .item_inner .text_box .buttons h5 {
          color: var(--text-light) !important;
        }
      }

      .payment_info h1 {
        background: var(--bg-color) !important;
      }
    }
  }

  app-chat {
    ion-header {
      background: var(--bg-color) !important;
    }

    ion-content {
      --background: #000000d9;
    }

    .chat_container .chat_box {
      &.received .chat {
        background: var(--bg-color) !important;
      }
    }

    ion-footer {
      background: var(--bg-secondary) !important;
    }
  }

  app-my-account {
    ion-header {
      background: var(--bg-color) !important;
    }

    ion-list {
      border-color: var(--bg-secondary) !important;

      ion-item {
        background: var(--bg-color) !important;
      }
    }

    ion-footer {
      background: var(--bg-secondary) !important;

      .developed_by img {
        &.dark-theme-logo {
          display: inline !important;
        }

        &.light-theme-logo {
          display: none !important;
        }
      }
    }
  }

  app-saved-addresses {
    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-item {
      background: var(--bg-color) !important;

      .img_box {
        background: var(--bg-secondary) !important;
      }
    }

    ion-footer ion-button {
      --background: var(--bg-color) !important;
    }
  }

  app-about-us,
  app-support,
  app-terms-conditions {
    .banner {
      background: var(--bg-secondary) !important;

      .logo img {
        &.dark_theme_logo {
          display: inline !important;
        }

        &.light_theme_logo {
          display: none !important;
        }
      }
    }

    .container p {
      color: var(--text-light);
    }
  }

  app-support {
    .form p {
      color: var(--text-light);
    }
  }

  app-custom-delivery {
    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-card {
      background: var(--bg-color) !important;
    }

    .payment_info {
      h1 {
        background: var(--bg-color) !important;
      }

      ion-item {
        background: var(--bg-color) !important;
      }
    }
  }

  app-settings {
    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-item {
      background: var(--bg-color) !important;
    }
  }

  ion-tabs ion-tab-bar {
    --background: var(--bg-color) !important;

    ion-tab-button .tab-button {
      background: var(--bg-color) !important;

      .icon_box img {
        &.dark-them-icon {
          display: inline !important;
        }

        &.light-them-icon {
          display: none !important;
        }
      }

      &.selected {
        background: var(--bg-secondary) !important;
      }
    }
  }

  app-reviews {
    ion-header {
      background: var(--bg-color) !important;

      &::before {
        background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-item {
      background: var(--bg-color) !important;
    }
  }

  app-add-money,
  app-wallet {
    ion-header {
      background: var(--bg-color) !important;
    }

    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-item {
      background: var(--bg-color) !important;
    }
  }

  app-add-money {
    ion-header::before {
      background: var(--bg-color) !important;
    }

    .form ion-list ion-card {
      background: var(--bg-color) !important;
    }
  }

  app-verification,
  app-register,
  app-socila-login {
    .form {
      border-color: var(--bg-secondary) !important;
    }
  }

  app-offers {
    ion-content {
      &.bg_color {
        --background: var(--bg-secondary) !important;
      }
    }

    ion-list ion-item {
      background: var(--bg-color) !important;
      --background: var(--bg-color) !important;

      .item_inner {
        background: var(--bg-color) !important;

        .offers_details {
          h2 {
            color: var(--text-black) !important;
          }
        }
      }

      &::before,
      &::after {
        background: var(--bg-secondary) !important;
      }
    }
  }

  .book_table ion-row ion-col .option {
    background: var(--bg-secondary) !important;
  }

  app-ordering-method,
  app-table-booking {
    ion-footer .form {
      .radio_list ion-radio-group .scroll-x ion-item {
        background: var(--bg-secondary) !important;
      }

      .add_note ion-item {
        background: var(--bg-secondary) !important;
      }
    }
  }
}

ion-select {
  max-width: 100% !important;
}
